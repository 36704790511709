import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FeatureSide from '../../components/feature-side'

const FeatureIndex = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="名言アドバイザーブログ"
        pagepath="/feature/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../assets/images/feature/feature-index01.jpg"
							alt="名言アドバイザーブログ"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">名言アドバイザーブログ</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <h4 className="m-heading">ブログ一覧</h4>
                    <ul className="l-feature__list">
                      <li className="list__item">
                        <Link to="/feature/01/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/gandhi01.jpg"
                              alt="ガンジー"
                            />
                          </div>
                          <div className="list__text">
                            インド独立の父、マハトマ・ガンジーの言葉から学ぼう！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/02/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/artist01.jpg"
                              alt="夢に突き進むあなたへ。勇気をもらえる名言"
                            />
                          </div>
                          <div className="list__text">
                            夢に突き進むあなたへ。勇気をもらえる名言
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/03/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/retire01.jpg"
                              alt="定年退職を迎えたあなたへ！これからの人生を楽しむための名言"
                            />
                          </div>
                          <div className="list__text">
                            定年退職を迎えたあなたへ！これからの人生を楽しむための名言
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/04/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/ochikomu01.jpg"
                              alt="落ち込んでしまったあなたへ。前向きになれる力を持つ名言！"
                            />
                          </div>
                          <div className="list__text">
                          落ち込んでしまったあなたへ。前向きになれる力を持つ名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/05/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/jibunmama01.jpg"
                              alt="自分は自分のままでいい！勇気をもらえる名言！"
                            />
                          </div>
                          <div className="list__text">
                            自分は自分のままでいい！勇気をもらえる名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/06/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/business01.jpg"
                              alt="社会人のあなたへ！乗り越える強さをくれる名言！"
                            />
                          </div>
                          <div className="list__text">
                          社会人のあなたへ！乗り越える強さをくれる名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/07/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/student01.jpg"
                              alt="学生のあなたへ。人生を渡り切る強さをくれる名言！"
                            />
                          </div>
                          <div className="list__text">
                            学生のあなたへ。人生を渡り切る強さをくれる名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/08/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/manager01.jpg"
                              alt="人を導くあなたへ。上司・先生・指導者が知るべき名言！"
                            />
                          </div>
                          <div className="list__text">
                            人を導くあなたへ。上司・先生・指導者が知るべき名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/09/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/sports01.jpg"
                              alt="感動と勇気をもらえる。スポーツ選手の名言！"
                            />
                          </div>
                          <div className="list__text">
                          感動と勇気をもらえる。スポーツ選手の名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/10/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/gakusha01.jpg"
                              alt="最高の叡智をあなたに。学者の名言！"
                            />
                          </div>
                          <div className="list__text">
                            最高の叡智をあなたに。学者の名言！
                          </div>
                        </Link>
                      </li>

                      <li className="list__item">
                        <Link to="/feature/11/">
                          <div className="list__img">
                            <StaticImage
                              src="../../../assets/images/feature/challenge01.jpg"
                              alt="「君の心の中にある英雄を捨ててはいけないよ。」。チャレンジする勇気をくれる名言！"
                            />
                          </div>
                          <div className="list__text">
                          「君の心の中にある英雄を捨ててはいけないよ。」。チャレンジする勇気をくれる名言！
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default FeatureIndex